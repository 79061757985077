<template>
  <div
    class="flex text-sm rounded-full aspect-square"
  >
    <div class="bg-opacity-5 rounded-full h-16 overflow-clip aspect-square">
      <img
        v-if="currentOutfit"
        class="object-cover"
        :style="avatarStyles"
        :src="currentOutfit.image"
        alt=""
      />
      <div
        v-else
        class="h-16 aspect-square rounded-full bg-white flex items-center justify-center text-xl font-star"
      >
        ?
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { StyleValue } from "vue";

const props = defineProps<{
  fairyId: string;
}>();

const { fairyId } = toRefs(props);

const { currentOutfit, fairyLevel } = useFairyOutfit(fairyId);

const avatarStyles = computed<StyleValue>(() => {
  if (fairyLevel.value === "BABY") {
    return {
      height: `105px`,
      objectPosition: `42% -4px`,
    };
  }

  if (fairyLevel.value === "KID") {
    return {
      height: `150px`,
      objectPosition: `47% -25px`,
    };
  }

  if (fairyLevel.value === "GROWN") {
    return {
      height: `150px`,
      objectPosition: `55% -8px`,
    };
  }

  return {};
});
</script>
